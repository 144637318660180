export const getOktaDetails = () => {
  const oktaToken = localStorage.getItem('dct-okta-token');
  const oktaTokenObj = JSON.parse(oktaToken);
  if (oktaTokenObj && oktaTokenObj.accessToken !== undefined) {
    return oktaTokenObj;
  }
  return null;
};

export const getOktaToken = () => {
  const oktaTokenDetails = getOktaDetails();
  if (oktaTokenDetails !== null) {
    return oktaTokenDetails.accessToken.accessToken;
  }
  return '';
};

export const openApplications = [
  '@amgen/dct-root-config',
  '@amgen/dch-auth',
];

export const isAuthorized = (authMappingConstants: any) => {
  if (!authMappingConstants) {
    return false;
  }
  const routeSlice = window.location.pathname.split('/');
  const route = routeSlice[1] == '' ? 'home' : routeSlice[1];
  if (authMappingConstants[route]) {
    return true;
  }
  return false;
};

export const isApplicationAuthorized = (
  authMappingConstants: any,
  applicationName: string,
) => {
  const mapping = {
    '@amgen/dct-aoh': 'aoh',
  };

  if (!authMappingConstants) {
    return false;
  }
  if (mapping[applicationName]) {
    return true;
  }
  return false;
};
