import { registerApplication, start, addErrorHandler } from 'single-spa';
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from 'single-spa-layout';
import microfrontendLayout from './microfrontend-layout.html';
import '../node_modules/@gitlab-rtsensing/component-library/lib/global.min.css';

import {
  getOktaToken,
  openApplications,
} from './auth-manager';
import { matomoEmbed } from './matomo';
import { errorHandler } from './utils/error-handler';

const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});

const layoutEngine = constructLayoutEngine({ routes, applications });

const loadOtherApps = (permissions: any, userName: string) => {
  // matomoEmbed(userName);
  const token = getOktaToken();
  applications.forEach(application => {
    // Register applications only when user is authenticated, but open application will load always includes root and auth
    if (token || openApplications.includes(application.name)) {
      application.customProps = {
        permissions: permissions
      };
      registerApplication(application);
    }
  });
};

registerApplication({
  name: '@amgen/dct-auth',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  app: () => System.import('@amgen/dct-auth'),
  activeWhen: '/',
  customProps: {
    callback: loadOtherApps,
  },
});

addErrorHandler(errorHandler);

layoutEngine.activate();
start();
