import { AppError, getAppStatus, LOAD_ERROR } from 'single-spa';
import errHTML from '../error-page.html';

export function errorHandler(err: AppError) {
  if (getAppStatus(err.appOrParcelName) === LOAD_ERROR) {
    System.delete(System.resolve(err.appOrParcelName));
  }

  const el = ErrorPage(err);
  el.id = 'error-page';
  document.getElementById('dct-main-content').appendChild(el);
  window.addEventListener('popstate', () => {
    if ((document.getElementById('error-page'), { once: true })) {
      el.remove();
    }
  });
}

function ErrorPage(err: AppError): HTMLElement {
  const handleRetryClick = () => {
    window.location.reload();
  };

  const el = document.createElement('div');
  el.innerHTML = errHTML;
  el.querySelector('.error-name').innerHTML = err.appOrParcelName;
  el.querySelector('#retry-button').addEventListener('click', handleRetryClick);

  return el;
}
